import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="my-rides-filter"
export default class extends Controller {
  static targets = ["button", "switch"]

  static values = {
    user: String,
    myRides: { type: Boolean, default: false }
  }

  connect() {
    console.log("Connected to my-rides-filter controller")
  }

  toggle(event) {
    this.myRidesValue = !this.myRidesValue
    if (this.myRidesValue) {
      this.buttonTarget.classList.remove("bg-gray-200")
      this.buttonTarget.classList.add("bg-blue-500")
      this.switchTarget.classList.remove("translate-x-0")
      this.switchTarget.classList.add("translate-x-5")
      this.addRideFields()
    } else {
      this.buttonTarget.classList.remove("bg-blue-500")
      this.buttonTarget.classList.add("bg-gray-200")
      this.switchTarget.classList.remove("translate-x-5")
      this.switchTarget.classList.add("translate-x-0")
      this.removeRideFields()
    }
    document.getElementById("rides-search-filter").requestSubmit()
  }

  addRideFields() {
    let joined_rides = document.createElement("input")
    joined_rides.setAttribute("type", "hidden")
    joined_rides.setAttribute("name", "q[ride_users_id_eq]")
    joined_rides.setAttribute("id", "ride_users_id_eq")
    joined_rides.setAttribute("value", this.userValue)

    let searchForm = document.getElementById("rides-search-filter")
    searchForm.appendChild(joined_rides)

    let leading_rides = document.createElement("input")
    leading_rides.setAttribute("type", "hidden")
    leading_rides.setAttribute("name", "q[ride_leaders_id_eq]")
    leading_rides.setAttribute("id", "ride_leaders_id_eq")
    leading_rides.setAttribute("value", this.userValue)
    searchForm.appendChild(leading_rides)

    let organizing_rides = document.createElement("input")
    organizing_rides.setAttribute("type", "hidden")
    organizing_rides.setAttribute("name", "q[ride_organizers_id_eq]")
    organizing_rides.setAttribute("id", "ride_organizers_id_eq")
    organizing_rides.setAttribute("value", this.userValue)
    searchForm.appendChild(organizing_rides)
  }

  removeRideFields() {
    document.getElementById("ride_users_id_eq").remove()
    document.getElementById("ride_leaders_id_eq").remove()
    document.getElementById("ride_organizers_id_eq").remove()
  }
}
