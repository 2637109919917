import { Controller } from "@hotwired/stimulus"
import 'nouislider/dist/nouislider.css';
import * as noUiSlider from 'nouislider/dist/nouislider.min';

// Connects to data-controller="range-slider"
export default class extends Controller {
  static targets = [ "slider", "min", "max" ]
  static values = {
    min: Number,
    max: Number,
    step: Number,
    unit: String,
    submitOnChange: { type: Boolean, default: false }
  }

  connect() {
    let slider = this.sliderTarget
    let min = this.minValue
    let max = this.maxValue
    let step = this.stepValue || 1
    let unit = this.unitValue

    noUiSlider.create(slider, {
      start: [min, max],
      connect: true,
      tooltips: {
        to: function (value) {
          return `${Math.round(value)} ${unit}`
        },
        // 'from' the formatted value.
        // Receives a string, should return a number.
        from: function (value) {
          return Number(value.replace(',-', ''));
          // may need this conditonal for non numeric values
          // return value
          // return value + unit
          // return `${value} ${unit}`
          // console.log(`${value} ${unit}`)
        }
      },
      range: {
        'min': min,
        'max': max
      },
      step: step,
      pips: {
        mode: 'steps',
        // density: 4
        density: 100
      }
    });
    slider.noUiSlider.on('change', (values, handle) => {
      this.minTarget.value = values[0]
      this.maxTarget.value = values[1]

      if (this.submitOnChangeValue) {
        this.element.closest('form').requestSubmit()
      }
    });
  }

}
