import { DateController } from "@bullet-train/fields"
// overrides bullet_train-fields-1.3.18/app/javascript/controllers/fields/date_controller.js

export default class extends DateController {
  static values = {
    includeTime: Boolean,
    defaultTimeZones: Array,
    dateFormat: String,
    timeFormat: String,
    currentTimeZone: String,
    isAmPm: Boolean,
    pickerLocale: { type: Object, default: {} }
  }

  initPluginInstance() {
    const localeValues = this.pickerLocaleValue
    const isAmPm = this.isAmPmValue
    localeValues['format'] = this.includeTimeValue ? this.timeFormatValue : this.dateFormatValue

    $(this.displayFieldTarget).daterangepicker({
      singleDatePicker: true,
      timePicker: this.includeTimeValue,
      timePickerIncrement: 5,
      autoUpdateInput: false,
      autoApply: true,
      locale: localeValues,
      timePicker24Hour: !isAmPm,
    })

    $(this.displayFieldTarget).on('apply.daterangepicker', this.applyDateToField.bind(this))
    $(this.displayFieldTarget).on('cancel.daterangepicker', this.clearDate.bind(this))
    $(this.displayFieldTarget).on('input', this,this.displayFieldChange.bind(this));

    this.pluginMainEl = this.displayFieldTarget
    this.plugin = $(this.pluginMainEl).data('daterangepicker') // weird

    // Init time zone select
    if (this.includeTimeValue && this.hasTimeZoneSelectWrapperTarget) {
      this.timeZoneSelect = this.timeZoneSelectWrapperTarget.querySelector('select.select2')

      $(this.timeZoneSelect).select2({
        width: 'style'
      })

      const self = this

      $(this.timeZoneSelect).on('change.select2', function(event) {
        const currentTimeZoneEl = self.currentTimeZoneWrapperTarget.querySelector('a')
        const {value} = event.target
        $(self.timeZoneFieldTarget).val(value)
        $(currentTimeZoneEl).text(value)

        const selectedOptionTimeZoneButton = $('.selected-option-time-zone-button')

        if (self.defaultTimeZonesValue.includes(value)) {
          $('.time-zone-button').removeClass('button').addClass('button-alternative')
          selectedOptionTimeZoneButton.addClass('hidden').attr('hidden', true)
          $(`a[data-value="${value}"`).removeClass('button-alternative').addClass('button')
        } else {
          // deselect any selected button
          $('.time-zone-button').removeClass('button').addClass('button-alternative')
          selectedOptionTimeZoneButton.text(value)
          selectedOptionTimeZoneButton.attr('data-value', value).removeAttr('hidden')
          selectedOptionTimeZoneButton.removeClass(['hidden', 'button-alternative']).addClass('button')
        }

        self.resetTimeZoneUI()
      })
    }
  }
}
