import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="filter-group"
export default class extends Controller {
  static targets = [ "option" ]

  connect() {
    console.log("Connected to filter group controller")
  }

  submit() {
    this.element.closest('form').requestSubmit()
  }

}
